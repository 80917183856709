import { type GeoJSONFeature, type LngLat, type MapMouseEvent, type PaddingOptions, Point, Popup } from 'mapbox-gl';
import { type Dispatch, type ReactNode, type SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Map, type MapboxMap, type ViewStateChangeEvent } from 'react-map-gl';
import { find, first, random } from 'lodash';
import { Text } from '@chakra-ui/react';
import {
  showActivityMarkerTooltip,
  showQuiverPlotTooltip,
  showTidalDiamondTooltip,
  showVesselTooltip,
  showVesselTrackTooltip,
} from './utils/tooltip-utils';
import { mapCenters, sidePanelMapCenterOffsetPixels, timelineMapCenterOffsetPixels } from './constants';
import {
  activityMarkerLayer,
  getActiveLayerIds,
  operationLayer,
  quiverPlotLayer,
  tidalDiamondLayer,
  vesselShapeLayer,
  vesselShapeLayer3D,
} from './layers-config';
import {
  type ActivityMarkerFeatureProperties,
  type FeatureSearchArea,
  type HoverHandlerContext,
  type HoverHandlerTypedContext,
  MapLayer,
  type TidalDiamondFeatureProperties,
  type TooltipProperties,
  type VesselFeatureProperties,
  type VesselTrackFeatureProperties,
} from './types';
import { hasLayer, hideLayer, orderLayers, showLayer } from './utils/layer-utils';
import ChartSettings from './components/ChartSettings';
import {
  getAvailableVesselLayers,
  getAvailableVesselTrackMarkerLayers,
  getClosestQuiverPlotArrow,
} from './utils/map-utils';
import {
  type AnalyticsEvent,
  CONFIG_CODES,
  type Coordinates,
  Event,
  type Location,
  Page,
} from '../../../../global/types';
import { Mode, type TooltipRef, type VesselPosition, type VesselViewModel } from '../../types';
import { getConfig } from '../../../../global/utils/getConfig';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { type Timestamp } from '../../utils/timestamp';
import { Timer } from '../../utils/timer';
import { useMapContext } from '../../../../global/state/GlobalProvider';
import areEqual from '../../../../global/utils/locationUtils';
import { translate } from '../../../../global/translation';
import { useDetectMobileScreenWidth } from '../../../../hooks/useDetectMobileScreenWidth';
import { type MapOrchestrator } from '../../hooks/useMapOrchestration';
import { useAppContext } from '../../../../global/components/AppContextProvider';
import { useFetchSecret } from '../../../../hooks/useFetchSecret';
import { secretNames } from '../../../../global/constants';

const MAPBOX_DEFAULT_STYLE = getConfig(CONFIG_CODES.REACT_APP_MAPBOX_STYLE);
const MAPBOX_SATELLITE_STYLE = getConfig(CONFIG_CODES.REACT_APP_MAPBOX_SATELLITE_STYLE);
const MAPBOX_NAUTICAL_STYLE = getConfig(CONFIG_CODES.REACT_APP_MAPBOX_NAUTICAL_STYLE);
const MAX_ARROW_DISTANCE_FROM_POINT = Number(getConfig(CONFIG_CODES.MAX_ARROW_DISTANCE_FROM_POINT_IN_PIXELS));
const minTooltipEventDurationInSeconds = 2;

interface Props {
  children: ReactNode;
  vesselPositions: VesselPosition[];
  mapCenter: Location;
  isLockedOnTarget: boolean;
  shouldDisableKeyboard?: boolean;
  setLockedOnTarget: Dispatch<SetStateAction<boolean>>;
  onTargetVesselChange: (mmsi: string) => void;
  targetVessel: VesselViewModel;
  timestamp: Timestamp;
  page: Page;
  operation: string;
  onOperationSelect: (value: string) => void;
  isWorldView: boolean;
  isTripsPanelOpen: boolean;
  pageMode: Mode;
  zoomOrchestrator: MapOrchestrator;
  setActiveTidalDiamond: (tidalDiamondId: string) => void;
  timezone: string;
}

const MapComponent = ({
  children,
  vesselPositions,
  mapCenter,
  isLockedOnTarget,
  shouldDisableKeyboard = false,
  setLockedOnTarget,
  onTargetVesselChange,
  targetVessel,
  timestamp,
  page,
  operation,
  onOperationSelect,
  isWorldView,
  isTripsPanelOpen,
  pageMode,
  zoomOrchestrator,
  setActiveTidalDiamond,
  timezone,
}: Props): JSX.Element => {
  const isMobileScreenWidth = useDetectMobileScreenWidth();
  const { settings } = useMapContext();
  const { mode } = useAppContext();
  const mapToken = useFetchSecret(secretNames.mapboxToken);
  const {
    map: mapRef,
    onTransitionToLiveview,
    onTransitionToPlayback,
    onMapZoomAdjustment,
    getInitialZoomLevel,
    onMapCenter,
    onVesselRecenter,
  } = zoomOrchestrator;
  const randomMapCenter = useMemo(() => {
    const index = random(0, mapCenters.length - 1, false);
    return mapCenters[index];
  }, [isWorldView]);

  const hasVesselPositions = useMemo(() => {
    return vesselPositions?.length > 0;
  }, [vesselPositions]);

  const configs: HoverHandlerContext[] = [
    {
      getSearchArea: e => e.point,
      getSearchableLayers: () => [operationLayer.id],
      order: 1,
    } as HoverHandlerContext,
    {
      getSearchArea: e => e.point,
      getSearchableLayers: map => (hasVesselPositions ? getAvailableVesselLayers(map) : []),
      order: 2,
      openTooltipHandler: (event, map, properties) => {
        openVesselTooltip(properties, map, event.point);
      },
    } as HoverHandlerTypedContext<VesselFeatureProperties>,
    {
      getSearchArea: e => e.point,
      getSearchableLayers: map =>
        hasVesselPositions && hasLayer(map, activityMarkerLayer.id) ? [activityMarkerLayer.id] : [],
      order: 3,
      openTooltipHandler: (event, map, properties) => {
        openActivityMarkerTooltip(properties, map);
      },
    } as HoverHandlerTypedContext<ActivityMarkerFeatureProperties>,
    {
      getSearchArea: e => e.point,
      getSearchableLayers: map => (hasVesselPositions ? getAvailableVesselTrackMarkerLayers(map) : []),
      order: 4,
      openTooltipHandler: (event, map, properties) => {
        openVesselTrackTooltip(properties, map);
      },
    } as HoverHandlerTypedContext<VesselTrackFeatureProperties>,
    {
      getSearchArea: e => e.point,
      getSearchableLayers: map => (hasLayer(map, tidalDiamondLayer.id) ? [tidalDiamondLayer.id] : []),
      order: 5,
      openTooltipHandler: (event, map, properties) => {
        openTooltip(
          t =>
            getEventFactory(Event.TidalDiamondHovered)(
              page,
              properties,
              t.current.timer.elapsed,
              window.location.href,
              timestamp
            ),
          () => {
            showTidalDiamondTooltip(properties, tooltipRef, map);
            setActiveTidalDiamond(properties.id);
          }
        );
      },
      noFeaturesFoundHandler: () => {
        setActiveTidalDiamond(null);
      },
    } as HoverHandlerTypedContext<TidalDiamondFeatureProperties>,
  ];

  const center = useMemo(() => {
    return isWorldView ? randomMapCenter : mapCenter;
  }, [isWorldView]);

  const paddingOptions = useMemo(() => {
    return {
      left: isTripsPanelOpen ? sidePanelMapCenterOffsetPixels : 0,
      bottom: page === Page.Playback ? timelineMapCenterOffsetPixels : 0,
    } as PaddingOptions;
  }, [isTripsPanelOpen, page]);

  useEffect(() => {
    if (pageMode === Mode.Playback) {
      return;
    }

    onTransitionToLiveview(isWorldView, targetVessel?.mmsi, shouldRecenter() ? getCenter() : null, paddingOptions);
  }, [isWorldView, isMobileScreenWidth, pageMode]);

  useEffect(() => {
    if (pageMode !== Mode.Playback) {
      return;
    }

    onTransitionToPlayback(
      isWorldView,
      vesselPositions,
      targetVessel?.mmsi,
      shouldRecenter() ? getCenter() : null,
      paddingOptions
    );
  }, [isWorldView, isMobileScreenWidth, pageMode, vesselPositions]);

  useEffect(() => {
    if (areEqual(center, randomMapCenter) || !center) {
      return;
    }

    const {
      coordinates: { lat, lon },
    } = center;
    mapRef?.current?.setCenter({
      lat,
      lng: lon,
    });
  }, [center, isWorldView, isMobileScreenWidth]);

  const mapCenterRef = useRef({ lng: center?.coordinates?.lon, lat: center?.coordinates?.lat } as LngLat);
  const tooltipRef = useRef<TooltipRef>({ popup: new Popup(), timer: new Timer() } as TooltipRef);

  const appInsights = useAppInsights();

  const [isPitched, setIsPitched] = useState(false);
  const targetVesselMmsi = targetVessel?.mmsi;

  const handleZoom = (event: ViewStateChangeEvent): void => {
    onMapZoomAdjustment(event.viewState.zoom, page, targetVessel?.mmsi);
  };

  const handlePan = (): void => {
    if (isLockedOnTarget) {
      setLockedOnTarget(false);
    }
  };

  const handlePanEnd = (): void => {
    const newCenter = mapRef.current.getMap().getCenter();
    const event = getEventFactory(Event.PanMap)(
      page,
      window.location.href,
      targetVesselMmsi,
      timestamp,
      mapCenterRef.current,
      newCenter
    );
    appInsights.trackAnalyticsEvent(event);
    mapCenterRef.current = newCenter;
  };

  const handleMouseMove = (event: MapMouseEvent): void => {
    const map = mapRef?.current?.getMap();
    if (!map) {
      return;
    }

    disableKeyboardIfNeeded(map);
    mapRef.current.getCanvas().style.cursor = '';

    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      const layers = config.getSearchableLayers(map);
      if (!layers.length) {
        continue;
      }

      const searchArea = config.getSearchArea(event);
      const features = getFeatures(searchArea, layers);

      if ((features?.length ?? 0) === 0) {
        config.noFeaturesFoundHandler?.();
        continue;
      }

      handleHover(event, map, features, config);
      return;
    }

    if (!isQuiverPlotTooltipVisible()) {
      closeTooltip();
    }
  };

  const isQuiverPlotTooltipVisible = (): boolean => {
    return tooltipRef.current.popup.getElement()?.className.includes('quiver-plot-tooltip');
  };

  const getFeatures = (searchArea: FeatureSearchArea, layers: string[]): GeoJSONFeature[] => {
    return mapRef.current.queryRenderedFeatures(searchArea, { layers });
  };

  const disableKeyboardIfNeeded = (map: MapboxMap): void => {
    if (shouldDisableKeyboard && map?.keyboard?.isEnabled()) {
      map.keyboard.disable();
    }
  };

  const handleHover = <TProperties extends TooltipProperties>(
    event: MapMouseEvent,
    map: MapboxMap,
    features: GeoJSONFeature[],
    config: HoverHandlerTypedContext<TProperties>
  ): void => {
    mapRef.current.getCanvas().style.cursor = 'pointer';
    const feature = first(features);
    const properties = feature.properties as TProperties;

    if (tooltipRef.current.popup.isOpen()) {
      if (tooltipRef.current.popup.getElement().id === properties.tooltipId) {
        return;
      }
      closeTooltip();
    }

    config.openTooltipHandler?.(event, map, properties);
  };

  const openTooltip = (
    eventOnCloseFactory: (tooltip: React.MutableRefObject<TooltipRef>) => AnalyticsEvent,
    showTooltipLogic: () => void
  ): void => {
    showTooltipLogic();
    tooltipRef.current.eventOnCloseFactory = eventOnCloseFactory;
    tooltipRef.current.timer.start();
  };

  const openVesselTooltip = (properties: VesselFeatureProperties, map: mapboxgl.Map, point: Point): void => {
    openTooltip(
      t =>
        getEventFactory(Event.VesselHovered)(
          page,
          properties,
          t.current.timer.elapsed,
          window.location.href,
          timestamp
        ),
      () => {
        showVesselTooltip(properties, tooltipRef, map, point);
      }
    );
  };

  const openVesselTrackTooltip = (trackProperties: VesselTrackFeatureProperties, map: mapboxgl.Map): void => {
    openTooltip(
      () =>
        getEventFactory(Event.VesselTrackHovered)(
          page,
          operation,
          timestamp,
          trackProperties.speed,
          trackProperties.mmsi
        ),
      () => {
        showVesselTrackTooltip(trackProperties, tooltipRef, map, timezone);
      }
    );
  };

  const openActivityMarkerTooltip = (properties: ActivityMarkerFeatureProperties, map: mapboxgl.Map): void => {
    openTooltip(
      () => getEventFactory(Event.ActivityMarkerHovered)(page, operation, timestamp, properties.speed, properties.mmsi),
      () => {
        showActivityMarkerTooltip(properties, tooltipRef, map, timezone);
      }
    );
  };

  const closeTooltip = (): void => {
    tooltipRef.current.popup.remove();
    tooltipRef.current.timer.stop();

    if (tooltipRef.current.timer.elapsed.toSeconds() >= minTooltipEventDurationInSeconds) {
      appInsights.trackAnalyticsEvent(tooltipRef.current.eventOnCloseFactory(tooltipRef));
    }
    tooltipRef.current.timer.reset();
  };

  const handleClick = (event: MapMouseEvent): void => {
    const map = mapRef?.current?.getMap();
    if (hasLayer(map, operationLayer.id)) {
      const operationFeatures = mapRef.current.queryRenderedFeatures(event.point, {
        layers: [operationLayer.id],
      });
      if (operationFeatures.length > 0) {
        onOperationSelect(first(operationFeatures).properties.key);
      }
    }

    if (vesselPositions.length > 0 && map) {
      const features = getFeatures(event.point, getAvailableVesselLayers(map));
      if (features.length > 0) {
        const feature = first(features);
        onTargetVesselChange(feature.properties.mmsi);
      }
    }

    if (canDisplayQuiverPlotTooltip(map, event)) {
      displayQuiverPlotTooltip(map, event);
    }
  };

  const displayQuiverPlotTooltip = (map: MapboxMap, event: MapMouseEvent): void => {
    const quiverPlotArrows = getFeatures(getQuiverPlotArrowsSearchBox(event.point), [quiverPlotLayer.id]);

    if (!quiverPlotArrows.length) {
      return;
    }

    const closestArrow = getClosestQuiverPlotArrow(quiverPlotArrows, event);
    const aiEvent = getEventFactory(Event.TidalArrowClicked)(page, closestArrow, window.location.href, timestamp);
    appInsights.trackAnalyticsEvent(aiEvent);
    showQuiverPlotTooltip(closestArrow, tooltipRef, map);
  };

  const getQuiverPlotArrowsSearchBox = (clickedPoint: Point): [Point, Point] => {
    const { x, y } = clickedPoint;
    return [
      new Point(x - MAX_ARROW_DISTANCE_FROM_POINT, y - MAX_ARROW_DISTANCE_FROM_POINT),
      new Point(x + MAX_ARROW_DISTANCE_FROM_POINT, y + MAX_ARROW_DISTANCE_FROM_POINT),
    ];
  };

  const canDisplayQuiverPlotTooltip = (map: MapboxMap, event: MapMouseEvent): boolean => {
    if (!hasLayer(map, quiverPlotLayer.id)) {
      return false;
    }

    const allFeatures = getFeatures(
      event.point,
      getActiveLayerIds(settings).filter(l => l !== quiverPlotLayer.id)
    );
    return !allFeatures.length;
  };

  const handlePitchChange = (event: ViewStateChangeEvent): void => {
    const { pitch } = event.viewState;
    const map = mapRef.current.getMap();
    if (isEntering3DView(pitch)) {
      enable3DView(map);
      setIsPitched(true);
    } else if (isExiting3DView(pitch)) {
      exit3DView(map);
      setIsPitched(false);
    }
  };

  const isExiting3DView = (pitch: number): boolean => {
    return pitch === 0 && isPitched;
  };

  const isEntering3DView = (pitch: number): boolean => {
    return pitch > 0 && !isPitched;
  };

  const switchLayersFor3d = (map: MapboxMap): void => {
    showLayer(map, vesselShapeLayer3D.id);
    hideLayer(map, vesselShapeLayer.id);
  };

  const switchLayersFor2d = (map: MapboxMap): void => {
    showLayer(map, vesselShapeLayer.id);
    hideLayer(map, vesselShapeLayer3D.id);
  };

  const exit3DView = (map: MapboxMap): void => {
    switchLayersFor2d(map);
    const event = getEventFactory(Event.Exited3dView)(page, window.location.href, targetVesselMmsi, timestamp);
    appInsights.trackAnalyticsEvent(event);
  };

  const enable3DView = (map: MapboxMap): void => {
    switchLayersFor3d(map);
    const event = getEventFactory(Event.Entered3dView)(page, window.location.href, targetVesselMmsi, timestamp);
    appInsights.trackAnalyticsEvent(event);
  };

  const getVesselLocation = (): Coordinates => {
    const {
      location: { coordinates },
    } = find(vesselPositions, p => p.mmsi === targetVesselMmsi);

    return coordinates;
  };

  const getCenter = (): LngLat => {
    const { lat, lon } = getVesselLocation();
    return {
      lat,
      lng: lon,
    } as LngLat;
  };

  const recenterMapOnTargetVessel = (): void => {
    const center = getCenter();
    onVesselRecenter(page, targetVesselMmsi, center, paddingOptions);
    mapCenterRef.current = center;
  };

  const centerMap = (): void => {
    const center = getCenter();
    onMapCenter(center, paddingOptions);
    mapCenterRef.current = center;
  };

  const shouldRecenter = (): boolean => {
    return isLockedOnTarget && find(vesselPositions, x => x.mmsi === targetVesselMmsi) && !!mapRef.current;
  };

  useEffect(() => {
    if (shouldRecenter()) {
      recenterMapOnTargetVessel();
    }
  }, [isLockedOnTarget, targetVesselMmsi]);

  // Center map on targeted vessel when any change in dependency array occurs
  useEffect(() => {
    if (shouldRecenter()) {
      centerMap();
    }
  }, [vesselPositions, mapRef.current, isTripsPanelOpen]);

  const mapStyles = {
    [MapLayer.Default]: MAPBOX_DEFAULT_STYLE,
    [MapLayer.Satellite]: MAPBOX_SATELLITE_STYLE,
    [MapLayer.Nautical]: MAPBOX_NAUTICAL_STYLE,
  };

  const mapStyle = mapStyles[settings?.layer] || MAPBOX_DEFAULT_STYLE;

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (!map) {
      return;
    }

    if (!map.isStyleLoaded()) {
      return;
    }

    if (map.getStyle()?.sprite !== mapStyle) {
      map.setStyle(mapStyle);
    }
  }, [mapRef?.current, settings?.layer]);

  const handleStyleData = (): void => {
    const map = mapRef?.current?.getMap();

    if (!map) {
      return;
    }

    const pitch = map.getPitch();
    if (pitch > 0) {
      switchLayersFor3d(map);
    } else {
      switchLayersFor2d(map);
    }
  };

  useEffect(() => {
    const map = mapRef?.current?.getMap();

    if (!map) {
      return;
    }
    orderLayers(map);
  }, [settings?.showVesselNames, settings?.showVesselTracks, targetVessel?.mmsi, mapRef?.current]);

  return (
    <div className="map-container">
      {center && mapToken && (
        <Map
          initialViewState={{
            latitude: center.coordinates.lat,
            longitude: center.coordinates.lon,
            zoom: getInitialZoomLevel(mode, isWorldView),
          }}
          onZoom={handleZoom}
          onDrag={handlePan}
          onDragEnd={handlePanEnd}
          onClick={handleClick}
          onMouseMove={handleMouseMove}
          onRotate={handlePitchChange}
          mapboxAccessToken={mapToken}
          mapStyle={mapStyle}
          projection={{ name: 'globe' }}
          ref={mapRef}
          onStyleData={handleStyleData}
        >
          {children}
          {isWorldView && (
            <Text className="select-operation-message text-xxl-regular">{translate('SELECT_YOUR_OPERATION')}</Text>
          )}
          {!isWorldView && <ChartSettings page={page} operation={operation} />}
        </Map>
      )}
    </div>
  );
};

export default MapComponent;

import { type FC, useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import moment from 'moment';
import { Box } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import cx from 'classnames';
import { ActionType, carouselReducer, initialCarouselState, SlideDirection } from './CarouselReducer';
import BarChart from './components/BarChart';
import { useAdjacentDateRanges } from './hooks/useAdjacentDateRanges';
import { slidingDelayMs, swipeDurationMs } from './constants';
import BarChartHeader from './components/BarChartHeader';
import { type DateRange } from '../../../../global/types';
import { useTripsContext } from '../../../../global/state/GlobalProvider';
import { useFetchTripsEcoRatings } from '../../hooks/useFetchTripsEcoRatings';
import { type DailyEcoRating } from '../../../Trips/types';
import { getInitialBarChartRangeInDays } from '../../../Trips/utils/initial-trips-state';
import { type OperationViewModel } from '../Map/types';
import colors from '../../../../theme/colors';
import { translate } from '../../../../global/translation';
import { useDetectMobileScreenWidth } from '../../../../hooks/useDetectMobileScreenWidth';

interface Props {
  selectedDate: Date;
  setDateFilter: (date: Date) => void;
  width: number;
  height: number;
  operation: OperationViewModel;
}

const BarChartDatePicker: FC<Props> = ({ setDateFilter, selectedDate, width, height, operation }) => {
  const isMobileView = useDetectMobileScreenWidth();
  const { filters } = useTripsContext();
  const [carouselState, dispatch] = useReducer(carouselReducer, initialCarouselState);
  const [rangeSizeInDays, setRangeSizeInDays] = useState(getInitialBarChartRangeInDays(isMobileView));

  const [adjacentRanges, setCurrentRange] = useAdjacentDateRanges(selectedDate, rangeSizeInDays);
  const { data: tripsEcoRating } = useFetchTripsEcoRatings(filters, adjacentRanges.current.range, operation);
  const [barChartForDateRange, setBarChartForDateRange] = useState({});

  const isLastRange = useMemo(() => {
    const yesterday = moment.utc().subtract(1, 'day').startOf('day');

    return moment.utc(adjacentRanges.current.range.to).isAfter(yesterday);
  }, [adjacentRanges.current]);

  const slide = (direction: SlideDirection): void => {
    const isNext = direction === SlideDirection.NEXT;
    if (isLastRange && isNext) {
      return;
    }

    setCurrentRange(isNext ? adjacentRanges.next.range : adjacentRanges.previous.range);
    dispatch({ type: isNext ? ActionType.SLIDE_NEXT : ActionType.SLIDE_PREVIOUS });
    setDateFilter(null);

    setTimeout(() => {
      dispatch({ type: ActionType.STOP_SLIDING });
    }, slidingDelayMs);
  };

  const slidePrevious = (): void => {
    slide(SlideDirection.PREVIOUS);
  };

  const slideNext = (): void => {
    slide(SlideDirection.NEXT);
  };

  const handlers = useSwipeable({
    onSwipedLeft: slideNext,
    onSwipedRight: slidePrevious,
    swipeDuration: swipeDurationMs,
    preventScrollOnSwipe: true,
    trackMouse: true,
    trackTouch: true,
  });

  const getBarChart = (key: string, range: DateRange, dailyRatings: DailyEcoRating[]): JSX.Element => {
    return (
      <BarChart
        id={key}
        key={key}
        height={height}
        width={width}
        ecoRatings={dailyRatings}
        dateRange={range}
        setDateFilter={setDateFilter}
        selectedDate={selectedDate}
      />
    );
  };

  const getCachedBarChart = (key: string, range: DateRange, dailyRatings: DailyEcoRating[]): JSX.Element => {
    return barChartForDateRange[key]?.props.ecoRatings
      ? barChartForDateRange[key]
      : getBarChart(key, range, dailyRatings);
  };

  useEffect(() => {
    const result = { ...barChartForDateRange };
    Object.keys(barChartForDateRange).forEach(key => {
      const props = barChartForDateRange[key]?.props;
      if (props) {
        result[key] = getBarChart(key, props.dateRange, props.ecoRatings);
      }
    });
    setBarChartForDateRange(result);
  }, [selectedDate]);

  useLayoutEffect(() => {
    const { previous, current, next } = adjacentRanges;
    const result = {
      ...barChartForDateRange,
      [previous.key]: getCachedBarChart(previous.key, previous.range, null),
      [current.key]: getBarChart(current.key, current.range, tripsEcoRating?.dailyRatings),
      [next.key]: isLastRange ? null : getCachedBarChart(next.key, next.range, null),
    };

    setBarChartForDateRange(result);
  }, [adjacentRanges, tripsEcoRating?.dailyRatings]);

  const barCharts = useMemo(() => {
    const { previous, current, next } = adjacentRanges;

    return [previous.key, current.key, next.key].map(key => barChartForDateRange[key]);
  }, [barChartForDateRange]);

  return (
    <Box className="bar-chart-date-picker-container">
      <BarChartHeader
        tripsEcoRating={tripsEcoRating}
        selectedDate={selectedDate}
        rangeSize={rangeSizeInDays}
        setDaysInRange={setRangeSizeInDays}
      />
      <Box
        className={cx(
          'bar-chart-carousel-container',
          { sliding: carouselState.sliding },
          { previous: carouselState.sliding && carouselState.direction === SlideDirection.PREVIOUS },
          { next: carouselState.sliding && carouselState.direction === SlideDirection.NEXT }
        )}
        {...handlers}
      >
        {barCharts}
      </Box>
      <button type="button" className="slide-button left" aria-label={translate('PREVIOUS')} onClick={slidePrevious}>
        <ChevronLeftIcon boxSize={6} color={colors.gray[700]} />
      </button>
      <button
        type="button"
        className={cx('slide-button right', { disabled: isLastRange })}
        aria-label={translate('NEXT')}
        onClick={slideNext}
      >
        <ChevronRightIcon boxSize={6} color={colors.gray[700]} />
      </button>
    </Box>
  );
};

export default BarChartDatePicker;

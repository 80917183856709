import { useQuery } from 'react-query';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import STATUS from '../../../global/statusCodes';

export const useFetchVesselImage = (imo: string): string => {
  const getVesselImage = async (): Promise<string> => {
    if (!imo) return null;
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<string>(
      `trips/vessel-image/${imo}`
    );
    if (response && response.status === STATUS.OK) {
      const { data } = response;
      return data;
    }

    return null;
  };

  const result = useQuery<string | null, Error>(['vesselImage', imo], getVesselImage, {
    staleTime: Infinity,
  });
  return result.data;
};

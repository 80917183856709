import { find, max, min, orderBy, sortBy, uniq } from 'lodash';
import { type LayerProps, type MapboxMap } from 'react-map-gl';
import { type Position } from 'geojson';
import { getVesselDimensions } from './getVesselDimensions';
import { mapTracksToGradient } from './gradient-utils';
import isBetween from '../../../utils/isBetween';
import {
  type Feature,
  type VesselFeature,
  type ShipType,
  type Point,
  type LayerPriority,
  type VesselPropertyVariants,
  type Settings,
  MapLayer,
  type VesselLayerPropertyVariants,
  type VesselTrackViewModel,
  type VesselTrackFeature,
  type OperationViewModel,
  type OperationFeatureProperties,
  type PositionedActivity,
  type ActivityMarkerFeatureProperties,
  type QuiverPlotFeature,
  type QuiverPlotArrow,
  type TidalDiamondFeature,
  type TidalDiamond,
} from '../types';
import { type BaseActivity, type VesselPosition, type VesselViewModel } from '../../../types';
import { type NumberRange, type MmsiKey } from '../../../../../global/types';
import {
  InactivePositionAgeInMinutes,
  mapboxTidalIcons,
  positionAgeOpacityConfig,
  vesselTypeColorsConfig,
  vesselTypeIconConfig,
  vesselTypeOutlineColorsConfig,
} from '../constants';
import { translateAndFormat } from '../../../../../global/translation';
import { formatSpeed } from '../../../utils/speed-utils';
import { layerPriorities } from '../layers-config';
import colors from '../../../../../theme/colors';
import { activityTypeDisplayStrings } from '../../../../../global/constants';

export const getShipClusters = (
  vesselPositions: VesselPosition[],
  vesselData: Record<MmsiKey, VesselViewModel>,
  zoomLevel: number,
  zoomThresholdDrawCluster: number
): Feature[] => {
  const clusters: Feature[] = [];
  vesselPositions.forEach(position => {
    if (zoomLevel < zoomThresholdDrawCluster + 1) {
      const {
        coordinates: { lon: longitude, lat: latitude },
      } = position.location;
      clusters.push({
        type: 'Feature' as const,
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      });
    }
  });

  return clusters;
};

export const getVesselFeatures = (
  vesselPosition: VesselPosition,
  vesselData: Record<MmsiKey, VesselViewModel>,
  isVesselSelected: boolean,
  settings: Settings,
  mapZoom: number
): VesselFeature[] => {
  const vesselFeatures: VesselFeature[] = [];
  const { dimensions, mmsi, type, name } = vesselData[vesselPosition.mmsi];
  const { location, heading, speed, timestamp, positionAgeInMinutes, tidalDirection, tidalSpeed } = vesselPosition;
  const opacity = getOpacity(positionAgeInMinutes);
  const color = getVesselColor(type as ShipType, positionAgeInMinutes, isVesselSelected);
  const outlineColor = getVesselOutlineColor(type as ShipType, positionAgeInMinutes, isVesselSelected, settings);
  const icon = getVesselIcon(type as ShipType, positionAgeInMinutes, isVesselSelected);
  const isShape = dimensions.toBow + dimensions.toStern > getShapeThreshold(mapZoom);
  const text = translateAndFormat('VESSEL_NAME_SPEED_MAP_LABEL', name, formatSpeed(speed));

  vesselFeatures.push({
    id: `position-${mmsi}`,
    type: 'Feature',
    properties: {
      mmsi,
      type,
      name,
      location,
      heading,
      speed,
      timestamp,
      color,
      outlineColor,
      icon,
      positionAgeInMinutes,
      tidalDirection,
      tidalSpeed,
      opacity,
      isShape: false,
      isPoint: isShape,
      isTarget: isVesselSelected && !isShape,
      text,
      tooltipId: mmsi,
    },
    geometry: {
      type: 'Point',
      coordinates: [location.coordinates.lon, location.coordinates.lat] as Point,
    },
  });

  if (isShape) {
    vesselFeatures.push({
      id: `shape-${mmsi}`,
      type: 'Feature',
      properties: {
        mmsi,
        type,
        name,
        location,
        heading,
        speed,
        timestamp,
        color,
        outlineColor,
        icon,
        positionAgeInMinutes,
        tidalDirection,
        tidalSpeed,
        opacity,
        isShape: true,
        tooltipId: mmsi,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [getVesselDimensions(location, heading, dimensions) as Point[]],
      },
    });
  }
  return vesselFeatures;
};

export const getQuiverPlotFeatures = (arrows: QuiverPlotArrow[]): QuiverPlotFeature[] => {
  if (!arrows?.length) {
    return [];
  }
  const arrowsFiltered = arrows.filter(arrow => arrow.rate !== 0);
  const speeds = uniq(arrowsFiltered.map(a => a.rate));
  const iconsMap = mapSpeedsToIcons(speeds);

  return arrowsFiltered.map(arrow => {
    const { id, location, set, rate } = arrow;
    const {
      coordinates: { lat, lon },
    } = location;
    return {
      id: `quiver-plot-${id}`,
      type: 'Feature',
      properties: {
        location,
        icon: iconsMap[arrow.rate],
        set,
        rate,
        id,
        tooltipId: id,
      },
      geometry: {
        type: 'Point',
        coordinates: [lon, lat] as Point,
      },
    };
  });
};

const mapSpeedsToIcons = (speeds: number[]): Record<number, string> => {
  const result: Record<number, string> = {};
  const maxSpeed = max(speeds);
  const minSpeed = min(speeds);
  const delta = maxSpeed - minSpeed;
  const speedsSorted = sortBy(speeds, s => s);

  if (speedsSorted.length === 1) {
    result[minSpeed] = mapboxTidalIcons.tideArrow1;
    return result;
  }

  const thresholdToIconMap = getIconThresholdMap(minSpeed, maxSpeed, delta);

  speedsSorted.forEach(speed => {
    Object.keys(thresholdToIconMap).forEach(icon => {
      const range = thresholdToIconMap[icon];
      if (range.from < speed && range.to >= speed) {
        result[speed] = icon;
      }
    });
  });
  return result;
};

const getIconThresholdMap = (minSpeed: number, maxSpeed: number, speedDelta: number): Record<string, NumberRange> => {
  const firstThreshold = minSpeed + speedDelta * 0.25;
  const secondThreshold = minSpeed + speedDelta * 0.5;
  const thirdThreshold = minSpeed + speedDelta * 0.75;

  return {
    [mapboxTidalIcons.tideArrow0]: {
      from: 0,
      to: firstThreshold,
    },
    [mapboxTidalIcons.tideArrow1]: {
      from: firstThreshold,
      to: secondThreshold,
    },
    [mapboxTidalIcons.tideArrow2]: {
      from: secondThreshold,
      to: thirdThreshold,
    },
    [mapboxTidalIcons.tideArrow3]: {
      from: thirdThreshold,
      to: maxSpeed,
    },
  };
};

export const getTidalDiamondsFeatures = (
  tidalDiamonds: TidalDiamond[],
  activeTidalDiamond: string
): TidalDiamondFeature[] => {
  if (!tidalDiamonds?.length) {
    return [];
  }
  return tidalDiamonds
    .filter(td => td.rate !== 0)
    .map(td => {
      const { id, location, set, rate, hoursAfterHighWater } = td;
      const {
        coordinates: { lat, lon },
      } = location;
      return {
        id: `tidal-diamond-${id}`,
        type: 'Feature',
        properties: {
          id,
          location,
          icon: activeTidalDiamond === id ? mapboxTidalIcons.tideDiamondHover : mapboxTidalIcons.tideDiamondDefault,
          set,
          rate,
          hoursAfterHighWater,
          tooltipId: id,
        },
        geometry: {
          type: 'Point',
          coordinates: [lon, lat] as Point,
        },
      };
    });
};

export const getVesselsFeatures = (
  vesselPositions: VesselPosition[],
  vesselData: Record<MmsiKey, VesselViewModel>,
  settings: Settings,
  mapZoom: number
): VesselFeature[] => {
  const vesselsFeatures: VesselFeature[] = [];
  if (vesselPositions && vesselPositions.length > 0) {
    vesselPositions.forEach(position => {
      const vesselFeatures = getVesselFeatures(position, vesselData, false, settings, mapZoom);
      vesselsFeatures.push(...vesselFeatures);
    });
  }
  return vesselsFeatures;
};

export const getVesselTrackFeatures = (tracks: VesselTrackViewModel[]): Array<VesselTrackFeature | Feature> => {
  const trackPositions = mapTracksToPositions(tracks);
  const trackMarkers =
    tracks?.map(t => {
      return {
        id: `past-vessel-track-marker-${t.timestamp}`,
        type: 'Feature' as const,
        properties: {
          isTrackMarker: true,
          mmsi: t.mmsi,
          timestamp: t.timestamp,
          speed: t.speed,
          location: t.location,
          tooltipId: t.timestamp.toString(),
        },
        geometry: {
          type: 'Point' as const,
          coordinates: [t.location.coordinates.lon, t.location.coordinates.lat],
        },
      } as VesselTrackFeature;
    }) ?? [];

  return [
    ...trackMarkers,
    {
      id: 'past-vessel-track',
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: trackPositions,
      },
    },
  ];
};

export const getFutureVesselTrackFeatures = (tracks: VesselTrackViewModel[]): Array<VesselTrackFeature | Feature> => {
  const trackPositions = mapTracksToPositions(tracks);
  const trackMarkers =
    tracks?.map(t => {
      return {
        id: `future-vessel-track-marker-${t.timestamp}`,
        type: 'Feature' as const,
        properties: {
          mmsi: t.mmsi,
          isTrackMarker: true,
          timestamp: t.timestamp,
          speed: t.speed,
          location: t.location,
        },
        geometry: {
          type: 'Point' as const,
          coordinates: [t.location.coordinates.lon, t.location.coordinates.lat],
        },
      };
    }) ?? [];

  return [
    ...trackMarkers,
    {
      id: 'future-vessel-track',
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: trackPositions,
      },
    },
  ];
};

export const getVesselTrackLayerWithGradient = (
  layer: LayerProps,
  tracks: VesselTrackViewModel[],
  activities: BaseActivity[] = []
): LayerProps => {
  const gradientLayer = { ...layer };
  gradientLayer.paint = { ...layer.paint, 'line-gradient': mapTracksToGradient(tracks, activities) };

  return gradientLayer;
};

export const getJobTrackFeatures = (jobTracks: VesselTrackViewModel[]): Array<VesselTrackFeature | Feature> => {
  const trackPositions = mapTracksToPositions(jobTracks);

  return [
    {
      id: 'job-vessel-track',
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: trackPositions,
      },
    },
  ];
};

export const getShapeThreshold = (mapZoom: number): number => {
  // Zooom is logarithmic. Each point doubles the map distance.
  // Boats with the length ranging from roughly 20-40 m should change icon at zoomlevel 14.75
  // hence the equation
  const boatLength = 40;
  const boatZoomLevelThreshold = 14.75;
  return boatLength * 2 ** (boatZoomLevelThreshold - mapZoom);
};

export const getTargetedVesselCoordinates = (vesselPositions: VesselPosition[], targetVesselMmsi: string): number[] => {
  // Update the indicator that tells if a vessel is selected
  const position = find(vesselPositions, x => x.mmsi === targetVesselMmsi);
  if (position) {
    const {
      coordinates: { lon: longitude, lat: latitude },
    } = position.location;
    return [longitude, latitude];
  }
  return [];
};

export const getOpacity = (positionAgeInMinutes: number): number => {
  return find(positionAgeOpacityConfig, c => isBetween(positionAgeInMinutes, c.from, c.to))?.opacity ?? 0;
};

export const getVesselColor = (shipType: ShipType, positionAgeInMinutes: number, isVesselSelected: boolean): string => {
  if (positionAgeInMinutes >= InactivePositionAgeInMinutes) {
    return selectPropertyVariant(vesselTypeColorsConfig.Inactive, isVesselSelected);
  }

  return selectPropertyVariant(vesselTypeColorsConfig[shipType], isVesselSelected);
};

export const getVesselOutlineColor = (
  shipType: ShipType,
  positionAgeInMinutes: number,
  isVesselSelected: boolean,
  settings: Settings
): string => {
  if (positionAgeInMinutes >= InactivePositionAgeInMinutes) {
    return selectPropertyVariant(
      selectLayerPropertyVariant(vesselTypeOutlineColorsConfig.Inactive, settings),
      isVesselSelected
    );
  }

  return selectPropertyVariant(
    selectLayerPropertyVariant(vesselTypeOutlineColorsConfig[shipType], settings),
    isVesselSelected
  );
};

export const getVesselIcon = (shipType: ShipType, positionAgeInMinutes: number, isVesselSelected: boolean): string => {
  if (positionAgeInMinutes >= InactivePositionAgeInMinutes) {
    return selectPropertyVariant(vesselTypeIconConfig.Inactive, isVesselSelected);
  }

  return selectPropertyVariant(vesselTypeIconConfig[shipType], isVesselSelected);
};

const selectPropertyVariant = <T>(variants: VesselPropertyVariants<T>, isVesselSelected: boolean): T => {
  const { selected, notSelected } = variants;
  return isVesselSelected ? selected : notSelected;
};

const selectLayerPropertyVariant = <T>(
  variants: VesselLayerPropertyVariants<T>,
  settings: Settings
): VesselPropertyVariants<T> => {
  switch (settings?.layer) {
    case MapLayer.Default:
      return variants.default;
    case MapLayer.Satellite:
      return variants.satellite;
    case MapLayer.Nautical:
      return variants.nautical;
    default:
      return variants.default;
  }
};

export const showLayer = (map: MapboxMap, layerId: string): void => {
  if (!hasLayer(map, layerId)) {
    return;
  }

  map.setLayoutProperty(layerId, 'visibility', 'visible');
};

export const hideLayer = (map: MapboxMap, layerId: string): void => {
  if (!hasLayer(map, layerId)) {
    return;
  }

  map.setLayoutProperty(layerId, 'visibility', 'none');
};

export const hasLayer = (map: MapboxMap, layerId: string): boolean => {
  return !!map.getLayer(layerId);
};

export const orderLayers = (map: MapboxMap): void => {
  const ordered = orderBy(layerPriorities, l => l.priority, 'desc');

  ordered.forEach(l => {
    if (!hasLayer(map, l.layerId)) {
      return;
    }

    const layer = map.getLayer(l.layerId);
    const beforeLayerId = getExistingLayerWithLowerPriority(map, l);
    map.moveLayer(layer.id, beforeLayerId);
  });
};

const getExistingLayerWithLowerPriority = (map: MapboxMap, layer: LayerPriority): string => {
  const lessPrioritizedLayers = orderBy(
    layerPriorities.filter(l => l.priority < layer.priority),
    l => l.priority,
    'asc'
  );

  const candidate = lessPrioritizedLayers.find(c => hasLayer(map, c.layerId));
  return candidate ? candidate.layerId : null;
};

const mapTracksToPositions = (tracks: VesselTrackViewModel[]): Position[] => {
  return tracks?.map(t => [t.location.coordinates.lon, t.location.coordinates.lat]) ?? [];
};

export const getOperationFeatures = (
  operations: OperationViewModel[],
  currentOperation: string
): Array<Feature<OperationFeatureProperties>> => {
  return operations
    .filter(o => o.name !== currentOperation)
    .map(o => {
      return {
        type: 'Feature',
        properties: {
          name: o.name,
          key: o.key,
        },
        geometry: {
          type: 'Point',
          coordinates: [o.location.coordinates.lon, o.location.coordinates.lat] as Point,
        },
      };
    });
};

export const getActivityMarkerFeatures = (
  activities: PositionedActivity[]
): Array<Feature<ActivityMarkerFeatureProperties>> => {
  return activities?.map(activity => {
    return {
      id: `activity-marker-${activity.activityId}`,
      type: 'Feature' as const,
      properties: {
        mmsi: activity.mmsi,
        color: activity.isSpeedLimited ? colors.primary[500] : colors.gray[400],
        type: activityTypeDisplayStrings[activity.type],
        timestamp: activity.timestamp,
        speed: activity.speed,
        location: activity.startLocation,
        tooltipId: activity.timestamp.toString(),
      },
      geometry: {
        type: 'Point' as const,
        coordinates: [activity.startLocation.coordinates.lon, activity.startLocation.coordinates.lat],
      },
    } as Feature<ActivityMarkerFeatureProperties>;
  });
};

import { type LayerProps } from 'react-map-gl';
import {
  layerSources,
  tidalDataMinZoomLevel,
  vesselNamesMinZoomLevel,
  vesselShapesMinZoomLevel,
  vesselsMinZoomLevel,
} from './constants';
import { MapLayer, type Settings, type LayerPriority } from './types';
import colors from '../../../../theme/colors';
import { correctSpeedLimitColor } from '../../utils/speed-utils';

export const nauticalLayer: LayerProps = {
  id: 'nautical-map-layer',
  type: 'raster',
  maxzoom: 22,
  paint: {
    'raster-opacity': 0.7,
  },
  layout: {
    visibility: 'visible',
  },
  minzoom: 0,
};

export const vesselShapeLayer: LayerProps = {
  id: 'vessel-shape-layer',
  type: 'fill',
  source: layerSources.vessels,
  filter: ['get', 'isShape'],
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': ['get', 'opacity'],
    'fill-outline-color': ['get', 'outlineColor'],
  },
  layout: {
    visibility: 'visible',
  },
  minzoom: vesselShapesMinZoomLevel,
};

export const vesselShapeLayer3D: LayerProps = {
  id: 'vessel-shape-layer-3d',
  type: 'fill-extrusion',
  source: layerSources.vessels,
  filter: ['get', 'isShape'],
  paint: {
    'fill-extrusion-color': ['get', 'color'],
    'fill-extrusion-height': 5,
    'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
  },
  layout: {
    visibility: 'none',
  },
  minzoom: vesselShapesMinZoomLevel,
};

export const targetVesselShapeLayer: LayerProps = {
  id: 'target-vessel-shape-layer',
  type: 'fill',
  source: layerSources.targetVessel,
  filter: ['get', 'isShape'],
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': ['get', 'opacity'],
    'fill-outline-color': ['get', 'outlineColor'],
  },
  layout: {
    visibility: 'visible',
  },
  minzoom: vesselShapesMinZoomLevel,
};

export const targetVesselShapeLayer3D: LayerProps = {
  id: 'target-vessel-shape-layer-3d',
  type: 'fill-extrusion',
  source: layerSources.targetVessel,
  filter: ['get', 'isShape'],
  paint: {
    'fill-extrusion-color': ['get', 'color'],
    'fill-extrusion-height': 5,
    'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
  },
  layout: {
    visibility: 'none',
  },
  minzoom: vesselShapesMinZoomLevel,
};

export const operationLayer: LayerProps = {
  id: 'operation-layer',
  type: 'symbol',
  source: layerSources.operations,
  layout: {
    visibility: 'visible',
    'icon-image': 'operation-icon',
    'icon-rotation-alignment': 'viewport',
    'icon-size': 0.4,
    'icon-allow-overlap': true,
    'text-field': ['get', 'name'],
    'text-font': ['DIN Pro Bold'],
    'text-size': 16,
    'text-max-width': 50,
    'text-offset': [0, 1],
    'text-anchor': 'top',
    'text-allow-overlap': true,
  },
  paint: {
    'text-color': colors.base.white,
    'text-opacity': 1,
    'text-halo-color': colors.base.black,
    'text-halo-width': 1,
    'text-halo-blur': 0.5,
  },
  maxzoom: vesselNamesMinZoomLevel,
};

export const vesselIconLayer: LayerProps = {
  id: 'vessel-icon-layer',
  type: 'symbol',
  source: layerSources.vessels,
  filter: ['all', ['!', ['get', 'isShape']], ['!', ['get', 'isPoint']]],
  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
  layout: {
    visibility: 'visible',
    'icon-image': ['get', 'icon'],
    'icon-rotate': ['get', 'heading'],
    'icon-rotation-alignment': 'map',
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
  minzoom: vesselsMinZoomLevel,
};

export const targetVesselIconLayer: LayerProps = {
  id: 'target-vessel-icon-layer',
  type: 'symbol',
  source: layerSources.targetVessel,
  filter: ['all', ['!', ['get', 'isShape']], ['!', ['get', 'isPoint']]],
  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
  layout: {
    visibility: 'visible',
    'icon-image': ['get', 'icon'],
    'icon-rotate': ['get', 'heading'],
    'icon-rotation-alignment': 'map',
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
  minzoom: vesselsMinZoomLevel,
};

export const vesselTextLayer: LayerProps = {
  id: 'vessel-text-layer',
  type: 'symbol',
  source: layerSources.vessels,
  filter: ['has', 'text'],
  layout: {
    visibility: 'visible',
    'text-allow-overlap': true,
    'text-field': ['get', 'text'],
    'text-anchor': 'left',
    'text-font': ['DIN Pro Medium'],
    'text-size': 10,
    'text-max-width': 15,
    'text-offset': [1, 1],
  },
  paint: {
    'text-color': colors.base.white,
    'text-opacity': 1,
    'text-halo-color': colors.base.black,
    'text-halo-width': 1,
    'text-halo-blur': 0.5,
  },
  minzoom: vesselNamesMinZoomLevel,
};

export const targetVesselTextLayer: LayerProps = {
  id: 'target-vessel-text-layer',
  type: 'symbol',
  source: layerSources.targetVessel,
  filter: ['has', 'text'],
  layout: {
    visibility: 'visible',
    'text-allow-overlap': true,
    'text-field': ['get', 'text'],
    'text-anchor': 'left',
    'text-font': ['DIN Pro Medium'],
    'text-size': 10,
    'text-max-width': 15,
    'text-offset': [1, 1],
  },
  paint: {
    'text-color': colors.base.white,
    'text-opacity': 1,
    'text-halo-color': colors.base.black,
    'text-halo-width': 1,
    'text-halo-blur': 0.5,
  },
  minzoom: vesselNamesMinZoomLevel,
};

export const vesselPointLayer: LayerProps = {
  id: 'vessel-point',
  type: 'circle',
  source: layerSources.vessels,
  filter: ['get', 'isPoint'],
  paint: {
    'circle-color': colors.base.white,
    'circle-radius': 2,
    'circle-stroke-width': 1,
    'circle-stroke-color': colors.base.black,
    'circle-pitch-alignment': 'map',
  },
};

export const targetVesselPointLayer: LayerProps = {
  id: 'target-vessel-point',
  type: 'circle',
  source: layerSources.targetVessel,
  filter: ['get', 'isPoint'],
  paint: {
    'circle-color': colors.base.white,
    'circle-radius': 2,
    'circle-stroke-width': 1,
    'circle-stroke-color': colors.base.black,
    'circle-pitch-alignment': 'map',
  },
};

export const targetVesselOutlineLayer: LayerProps = {
  id: 'target-vessel-outline',
  type: 'circle',
  source: layerSources.targetVessel,
  filter: ['get', 'isTarget'],
  paint: {
    'circle-radius': 40,
    'circle-pitch-alignment': 'map',
    'circle-stroke-opacity': 0.3,
    'circle-opacity': 0,
    'circle-stroke-width': 2,
    'circle-stroke-color': ['get', 'outlineColor'],
  },
  minzoom: vesselsMinZoomLevel,
};

export const pastVesselTrackLayer: LayerProps = {
  id: 'past-vessel-track-layer',
  source: layerSources.pastVesselTrack,
  type: 'line',
  paint: {
    'line-color': correctSpeedLimitColor,
    'line-opacity': 0.85,
    'line-width': 4.8,
  },
  layout: {
    'line-cap': 'round',
  },
  minzoom: vesselsMinZoomLevel,
};

export const futureVesselTrackLayer: LayerProps = {
  id: 'future-vessel-track-layer',
  source: layerSources.futureVesselTrack,
  type: 'line',
  paint: {
    'line-color': correctSpeedLimitColor,
    'line-opacity': 0.6,
    'line-width': 3,
  },
  layout: {
    'line-cap': 'round',
  },
  minzoom: vesselsMinZoomLevel,
};

export const pastVesselTrackMarkerLayer: LayerProps = {
  id: 'past-vessel-track-marker',
  type: 'circle',
  source: layerSources.pastVesselTrack,
  filter: ['get', 'isTrackMarker'],
  paint: {
    'circle-color': 'transparent',
    'circle-opacity': 1.0,
    'circle-radius': 8,
    'circle-stroke-width': 0,
    'circle-pitch-alignment': 'map',
  },
};

export const futureVesselTrackMarkerLayer: LayerProps = {
  id: 'future-vessel-track-marker',
  type: 'circle',
  source: layerSources.futureVesselTrack,
  filter: ['get', 'isTrackMarker'],
  paint: {
    'circle-color': 'transparent',
    'circle-opacity': 1.0,
    'circle-radius': 8,
    'circle-stroke-width': 0,
    'circle-pitch-alignment': 'map',
  },
};

export const jobTrackLayer: LayerProps = {
  ...pastVesselTrackLayer,
  id: 'job-track-layer',
  source: layerSources.jobTracks,
};

export const activityMarkerLayer: LayerProps = {
  id: 'activity-marker',
  type: 'circle',
  source: layerSources.activityMarkers,
  paint: {
    'circle-opacity': 1,
    'circle-radius': 6,
    'circle-color': ['get', 'color'],
    'circle-stroke-width': 1,
    'circle-stroke-color': colors.base.white,
    'circle-pitch-alignment': 'map',
  },
};

export const quiverPlotLayer: LayerProps = {
  id: 'quiver-plot-layer',
  type: 'symbol',
  source: layerSources.quiverPlot,
  layout: {
    visibility: 'visible',
    'icon-image': ['get', 'icon'],
    'icon-rotate': ['get', 'set'],
    'icon-rotation-alignment': 'map',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 6, 0.2, 8, 0.4, 10, 0.6, 12, 0.8, 14, 1.2],
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 0.5,
  },
  minzoom: tidalDataMinZoomLevel,
};

export const tidalDiamondLayer: LayerProps = {
  id: 'tidal-diamond-layer',
  type: 'symbol',
  source: layerSources.tidalDiamonds,
  layout: {
    visibility: 'visible',
    'icon-image': ['get', 'icon'],
    'icon-rotation-alignment': 'map',
    'icon-size': 0.7,
    'icon-allow-overlap': true,
  },
  minzoom: tidalDataMinZoomLevel,
};

export const shipClusterLayer: LayerProps = {
  id: 'ship-cluster-layer',
  type: 'circle',
  source: layerSources.vesselClusters,
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': colors.primary[500],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    'circle-stroke-width': 1,
    'circle-stroke-color': colors.base.black,
  },
};

export const shipClusterCountLayer: LayerProps = {
  id: 'ship-cluster-count-layer',
  type: 'symbol',
  source: layerSources.vesselClusters,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 20,
    'text-allow-overlap': true,
  },
  paint: {
    'text-color': colors.base.white,
  },
};

export const unclusteredShipLayer: LayerProps = {
  ...vesselPointLayer,
  id: 'unclustered-point',
  source: layerSources.vesselClusters,
  filter: ['!', ['has', 'point_count']],
};

export const allLayersIds: string[] = [
  nauticalLayer.id,
  vesselShapeLayer.id,
  vesselShapeLayer3D.id,
  targetVesselShapeLayer.id,
  targetVesselShapeLayer3D.id,
  operationLayer.id,
  vesselIconLayer.id,
  targetVesselIconLayer.id,
  vesselTextLayer.id,
  targetVesselTextLayer.id,
  vesselPointLayer.id,
  targetVesselPointLayer.id,
  targetVesselOutlineLayer.id,
  pastVesselTrackLayer.id,
  futureVesselTrackLayer.id,
  pastVesselTrackMarkerLayer.id,
  futureVesselTrackMarkerLayer.id,
  jobTrackLayer.id,
  shipClusterLayer.id,
  shipClusterCountLayer.id,
  unclusteredShipLayer.id,
  quiverPlotLayer.id,
  activityMarkerLayer.id,
  tidalDiamondLayer.id,
];

export const vesselTracksLayersIds: string[] = [
  pastVesselTrackLayer.id,
  futureVesselTrackLayer.id,
  pastVesselTrackMarkerLayer.id,
  futureVesselTrackMarkerLayer.id,
];

export const getActiveLayerIds = (settings: Settings): string[] => {
  return allLayersIds.filter(
    id =>
      (settings?.layer === MapLayer.Nautical || id !== nauticalLayer.id) &&
      (settings?.showVesselNames || id !== vesselTextLayer.id) &&
      (settings?.showVesselTracks || !vesselTracksLayersIds.includes(id))
  );
};

export const layerPriorities: LayerPriority[] = [
  {
    layerId: operationLayer.id,
    priority: 1,
  },
  {
    layerId: shipClusterLayer.id,
    priority: 2,
  },
  {
    layerId: shipClusterCountLayer.id,
    priority: 2,
  },
  {
    layerId: unclusteredShipLayer.id,
    priority: 2,
  },
  {
    layerId: vesselTextLayer.id,
    priority: 3,
  },
  {
    layerId: targetVesselTextLayer.id,
    priority: 3,
  },
  {
    layerId: targetVesselPointLayer.id,
    priority: 4,
  },
  {
    layerId: targetVesselShapeLayer.id,
    priority: 5,
  },
  {
    layerId: targetVesselShapeLayer3D.id,
    priority: 5,
  },
  {
    layerId: targetVesselIconLayer.id,
    priority: 5,
  },
  {
    layerId: targetVesselOutlineLayer.id,
    priority: 5,
  },
  {
    layerId: vesselPointLayer.id,
    priority: 6,
  },
  {
    layerId: vesselShapeLayer.id,
    priority: 7,
  },
  {
    layerId: vesselShapeLayer3D.id,
    priority: 7,
  },
  {
    layerId: vesselIconLayer.id,
    priority: 7,
  },
  { layerId: activityMarkerLayer.id, priority: 8 },
  {
    layerId: pastVesselTrackLayer.id,
    priority: 9,
  },
  {
    layerId: futureVesselTrackLayer.id,
    priority: 9,
  },
  {
    layerId: jobTrackLayer.id,
    priority: 9,
  },
  {
    layerId: pastVesselTrackMarkerLayer.id,
    priority: 10,
  },
  {
    layerId: quiverPlotLayer.id,
    priority: 11,
  },
  {
    layerId: tidalDiamondLayer.id,
    priority: 11,
  },
  {
    layerId: nauticalLayer.id,
    priority: 12,
  },
];

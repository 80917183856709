import { type Position } from 'geojson';
import { type MapboxMap } from 'react-map-gl';
import { type MapLayerMouseEvent, type PointLike, type Expression } from 'mapbox-gl';
import { type Location, type Speed } from '../../../../global/types';
import { type BaseActivity } from '../../types';

export enum ShipType {
  PilotBoat = 'Pilot Boat',
  Tug = 'Tug',
  PassengerShip = 'Passenger Ship',
  CargoShip = 'Cargo Ship',
  Tanker = 'Tanker',
  Other = 'Other',
  // Used for liveview to grey out ships
  Inactive = 'Inactive',
}

export interface PolygonGeometry {
  type: 'Polygon';
  coordinates: Position[][];
}

export interface LineGeometry {
  type: 'LineString';
  coordinates: Position[];
}

export interface PointGeometry {
  type: 'Point';
  coordinates: Position;
}

export interface Feature<TProperties = any> {
  id?: string | number;
  type: 'Feature';
  properties: TProperties;
  geometry: PolygonGeometry | PointGeometry | LineGeometry;
}

export interface VesselFeature extends Feature<VesselFeatureProperties> {}

export interface VesselTrackFeature extends Feature<VesselTrackFeatureProperties> {}

export interface QuiverPlotFeature extends Feature<TidalArrowFeatureProperties> {
  id: string | number;
}

export interface TidalDiamondFeature extends Feature<TidalDiamondFeatureProperties> {
  id: string | number;
}

export interface OperationFeatureProperties {
  name: string;
  key: string;
}

export interface OperationViewModel {
  name: string;
  location: Location;
  timezone: string;
  key: string;
}

export interface VesselTrackViewModel {
  mmsi: string;
  location: Location;
  timestamp: number;
  speed: Speed;
}

export interface VesselTracksViewModel {
  pastTracks: VesselTrackViewModel[];
  futureTracks: VesselTrackViewModel[];
  activities?: PositionedActivity[];
}

export interface PositionedActivity extends BaseActivity {
  mmsi: string;
  startLocation: Location;
  speed: Speed;
  timestamp: number;
}

export type Point = [number, number];

export interface TooltipProperties {
  tooltipId: string;
  location: Location;
}

export interface VesselFeatureProperties extends TooltipProperties {
  mmsi?: string;
  heading?: number;
  name?: string;
  type?: string;
  speed?: Speed;
  tidalSpeed?: number;
  tidalDirection?: number;
  positionAgeInMinutes?: number;
  color?: string;
  outlineColor?: string;
  icon?: string;
  timestamp?: number;
  opacity?: number;
  isShape?: boolean;
  isPoint?: boolean;
  text?: string;
  isTarget?: boolean;
}

export interface VesselTrackFeatureProperties extends TooltipProperties {
  mmsi?: string;
  speed?: Speed;
  timestamp?: number;
  isTrackMarker?: boolean;
  gradient?: Expression;
}

export interface ActivityMarkerFeatureProperties extends TooltipProperties {
  mmsi?: string;
  color: string;
  type: string;
  speed: Speed;
  timestamp: number;
}

export interface TidalArrowFeatureProperties extends TooltipProperties {
  id: string;
  rate?: number;
  set?: number;
}

export interface TidalDiamondFeatureProperties extends TooltipProperties {
  id: string;
  rate?: number;
  set?: number;
  hoursAfterHighWater?: number;
}

export enum MapLayer {
  Default = 'Default',
  Satellite = 'Satellite',
  Nautical = 'Nautical',
}

export interface Settings {
  layer: MapLayer;
  showVesselTracks: boolean;
  showVesselNames: boolean;
  showQuiverPlot: boolean;
  showNauticalMap: boolean;
}

export interface MapContext {
  settings: Settings;
}

export interface LayerPriority {
  layerId: string;
  priority: number;
}

export interface VesselPropertyVariants<T> {
  selected: T;
  notSelected: T;
}

export interface VesselLayerPropertyVariants<T> {
  default: VesselPropertyVariants<T>;
  satellite: VesselPropertyVariants<T>;
  nautical: VesselPropertyVariants<T>;
}

export interface QuiverPlotArrow {
  id: string;
  location: Location;
  set: number;
  rate: number;
}

export interface TidalDiamond {
  id: string;
  location: Location;
  set: number;
  rate: number;
  hoursAfterHighWater: number;
}

export interface TideDataViewModel {
  quiverPlotArrow: QuiverPlotArrow[];
  tidalDiamond: TidalDiamond[];
}

export type FeatureSearchArea = PointLike | [PointLike, PointLike];

export interface HoverHandlerContext {
  order: number;
  openTooltipHandler?: (event: MapLayerMouseEvent, map: MapboxMap, properties: any) => void;
  noFeaturesFoundHandler?: () => void;
  getSearchableLayers: (map: MapboxMap) => string[];
  getSearchArea: (event: MapLayerMouseEvent) => FeatureSearchArea;
}

export interface HoverHandlerTypedContext<TProperties extends TooltipProperties> extends HoverHandlerContext {
  openTooltipHandler?: (event: MapLayerMouseEvent, map: MapboxMap, properties: TProperties) => void;
}

import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from '@nivo/bar';
import { type BarDatum, type BarItemProps, type ComputedDatum } from '@nivo/bar/dist/types/types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';
import { BarItem } from './BarItem';
import BarChartAxisBottomTicks from './BarChartAxisBottomTicks';
import BarChartAxisRightTicks from './BarChartAxisRigthTicks';
import BarTooltip from './BarTooltip';
import { type DateRange } from '../../../../../global/types';
import { mapEcoRatingsToBarChartData, getPlaceholderBarChartData } from '../utils/bar-chart-utils';
import {
  barChartAdjustmentValue,
  barChartDateFormat,
  co2SurplusKey,
  defaultBarChartMaxValue,
  ecoRatingKey,
} from '../constants';
import { type DailyEcoRating } from '../../../../Trips/types';
import { type BarChartData } from '../types';

interface Props {
  id: string | number;
  height: number;
  width: number;
  ecoRatings: DailyEcoRating[];
  dateRange: DateRange;
  selectedDate: Date;
  setDateFilter: (date: Date) => void;
}

const BarChart: FC<Props> = ({ id, height, width, ecoRatings, dateRange, selectedDate, setDateFilter }) => {
  const [data, setData] = useState(
    mapEcoRatingsToBarChartData(ecoRatings?.length ? ecoRatings : getPlaceholderBarChartData(dateRange))
  );

  useEffect(() => {
    let newData: BarChartData[];
    if (ecoRatings?.length) {
      newData = mapEcoRatingsToBarChartData(ecoRatings);
    } else {
      newData = mapEcoRatingsToBarChartData(getPlaceholderBarChartData(dateRange));
    }
    setData(newData);
  }, [ecoRatings]);

  const { minValue, maxValue } = useMemo(() => {
    const minValue = minBy(data, d => d.estimatedCo2Surplus).estimatedCo2Surplus - barChartAdjustmentValue;
    const maxValue = maxBy(data, d => d.ecoRating).ecoRating + barChartAdjustmentValue;
    return {
      minValue,
      maxValue: Math.max(maxValue, defaultBarChartMaxValue),
    };
  }, [data]);

  const barItemClickHandler = useCallback(
    (barItem: ComputedDatum<BarDatum>) => {
      const selectedDateString = selectedDate && moment(selectedDate).format(barChartDateFormat);

      if (selectedDateString && barItem.data.day === selectedDateString) {
        setDateFilter(null);
      } else {
        setDateFilter(moment(barItem.indexValue).toDate());
      }
    },
    [selectedDate, setDateFilter]
  );

  const getBarItem = useCallback(
    (props: BarItemProps<BarDatum>) => {
      const selectedDateString = selectedDate && moment(selectedDate).format(barChartDateFormat);
      return <BarItem selectedDate={selectedDateString} {...props} />;
    },
    [selectedDate]
  );

  return (
    <div id={`bar-${id}`} className="bar-chart-container">
      <Bar
        data={data as any[]}
        keys={[ecoRatingKey, co2SurplusKey]}
        barComponent={getBarItem}
        onClick={barItemClickHandler}
        height={height}
        width={width}
        minValue={minValue}
        maxValue={maxValue}
        indexBy="id"
        margin={{ left: 20, right: 20, bottom: 40, top: 10 }}
        padding={0.4}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        defs={[
          {
            id: 'top',
            background: 'inherit',
            size: 4,
            padding: 1,
            stagger: true,
          },
        ]}
        fill={[
          {
            match: {
              id: ecoRatingKey,
            },
            id: 'top',
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        borderRadius={7}
        axisRight={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          truncateTickAt: 0,
          tickValues: [0, 50, 100],
          renderTick: BarChartAxisRightTicks,
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          truncateTickAt: 0,
          renderTick: BarChartAxisBottomTicks,
        }}
        axisLeft={null}
        enableLabel={false}
        gridYValues={[0, 25, 50, 75, 100]}
        role="application"
        layout="vertical"
        tooltip={BarTooltip}
      />
    </div>
  );
};

export default BarChart;

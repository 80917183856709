import { useState, useEffect } from 'react';
import { type FC } from 'react';
import { Image } from '@chakra-ui/react';
import { type Job } from '../../../../Trips/types';
import Tug from '../../../../../static/img/tug.png';
import { type VesselViewModel } from '../../../types';
import { type MmsiKey } from '../../../../../global/types';
import { useFetchVesselImage } from '../../../hooks/useFetchVesselImage';

interface Props {
  job: Job;
  vesselData: Record<MmsiKey, VesselViewModel>;
}

const JobCardVesselImage: FC<Props> = ({ job, vesselData }) => {
  const vessel = vesselData[job.vesselMmsi];
  const imo = vessel ? vessel.imo : null;
  const fetchedVesselImageBase64 = useFetchVesselImage(imo);
  const [vesselImageBase64, setVesselImageBase64] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedVesselImageBase64 && !vesselImageBase64) {
      setVesselImageBase64(fetchedVesselImageBase64);
    }
  }, [fetchedVesselImageBase64, vesselImageBase64]);

  return (
    <>
      {vesselImageBase64 ? (
        <img alt="vesselfinder.com" src={`data:image/jpeg;base64,${vesselImageBase64}`} />
      ) : (
        <Image src={Tug} />
      )}
    </>
  );
};

export default JobCardVesselImage;

import { type VesselLayerPropertyVariants, type ShipType, type VesselPropertyVariants } from './types';
import { type Location } from '../../../../global/types';
import colors from '../../../../theme/colors';

export const defaultLiveviewZoom = 11;

export const defaultPlaybackZoom = 14.5;

export const vesselRecenterZoom = 14.5;

export const vesselsClusterMaxZoomLevel = 7;

export const vesselsMinZoomLevel = 8;

export const tidalDataMinZoomLevel = 9.5;

export const vesselNamesMinZoomLevel = 13.4;

export const vesselShapesMinZoomLevel = 10;

export const approximationIntervalInMilliseconds = 15000;

export const InactivePositionAgeInMinutes = 30;

const AUS_MAP_CENTER: Location = {
  coordinates: {
    lat: -33.865143,
    lon: 151.2099,
  },
};
const EUR_MAP_CENTER: Location = {
  coordinates: {
    lat: 51.477928,
    lon: -0.001545,
  },
};
const EMEA_MAP_CENTER: Location = {
  coordinates: {
    lat: 26.189769,
    lon: 50.714409,
  },
};
const AMERICAS_MAP_CENTER: Location = {
  coordinates: {
    lat: 8.983333,
    lon: -79.51667,
  },
};

export const mapCenters = [AUS_MAP_CENTER, EUR_MAP_CENTER, EMEA_MAP_CENTER, AMERICAS_MAP_CENTER];
export const worldViewMapZoomLevel = 2;
export const worldViewMapMobileZoomLevel = 0.9;
export const sidePanelMapCenterOffsetPixels = 400;
export const timelineMapCenterOffsetPixels = 250;

export const layerSources = {
  nautical: 'nautical',
  vessels: 'vessels',
  targetVessel: 'target-vessel',
  vesselClusters: 'ship-clusters',
  jobTracks: 'job-tracks',
  pastVesselTrack: 'past-vessel-track',
  futureVesselTrack: 'future-vessel-track',
  operations: 'operations',
  activityMarkers: 'activity-markers',
  quiverPlot: 'quiver-plot',
  tidalDiamonds: 'tidal-diamonds',
};

export const vesselTypeColorsConfig: Record<ShipType, VesselPropertyVariants<string>> = {
  'Pilot Boat': {
    notSelected: colors.orangeDark[300],
    selected: colors.orangeDark[500],
  },
  'Passenger Ship': {
    notSelected: colors.pink[400],
    selected: colors.pink[600],
  },
  'Cargo Ship': {
    notSelected: colors.purple[300],
    selected: colors.purple[500],
  },
  Tug: {
    notSelected: colors.primary[400],
    selected: colors.primary[600],
  },
  Tanker: {
    notSelected: colors.blueDark[400],
    selected: colors.blueDark[600],
  },
  Inactive: {
    notSelected: colors.gray[300],
    selected: colors.gray[500],
  },
  Other: {
    notSelected: colors.yellow[400],
    selected: colors.yellow[600],
  },
};

export const vesselTypeOutlineColorsConfig: Record<ShipType, VesselLayerPropertyVariants<string>> = {
  'Pilot Boat': {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
  'Passenger Ship': {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
  'Cargo Ship': {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
  Tug: {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
  Tanker: {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
  Inactive: {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
  Other: {
    default: {
      notSelected: colors.gray[600],
      selected: colors.gray[600],
    },
    satellite: {
      notSelected: colors.gray[600],
      selected: colors.base.white,
    },
    nautical: {
      notSelected: colors.gray[600],
      selected: colors.base.black,
    },
  },
};

export const vesselTypeIconConfig: Record<ShipType, VesselPropertyVariants<string>> = {
  'Pilot Boat': {
    notSelected: 'vessel-icon-pilot',
    selected: 'vessel-icon-pilot-selected',
  },
  'Passenger Ship': {
    notSelected: 'vessel-icon-passenger',
    selected: 'vessel-icon-passenger-selected',
  },
  'Cargo Ship': {
    notSelected: 'vessel-icon-cargo',
    selected: 'vessel-icon-cargo-selected',
  },
  Tug: {
    notSelected: 'vessel-icon-tug',
    selected: 'vessel-icon-tug-selected',
  },
  Tanker: {
    notSelected: 'vessel-icon-tanker',
    selected: 'vessel-icon-tanker-selected',
  },
  Inactive: {
    notSelected: 'vessel-icon-inactive',
    selected: 'vessel-icon-inactive-selected',
  },
  Other: {
    notSelected: 'vessel-icon-other',
    selected: 'vessel-icon-other-selected',
  },
};

export const positionAgeOpacityConfig = [
  {
    from: 0,
    to: 12,
    opacity: 1,
  },
  {
    from: 12,
    to: 20,
    opacity: 0.75,
  },
  {
    from: 20,
    to: Infinity,
    opacity: 0.5,
  },
];

export const mapboxTidalIcons = {
  tideArrow0: 'tide_arrow_0',
  tideArrow1: 'tide_arrow_1',
  tideArrow2: 'tide_arrow_2',
  tideArrow3: 'tide_arrow_3',
  tideDiamondHover: 'tide_diamond_hover',
  tideDiamondDefault: 'tide_diamond_default',
};
